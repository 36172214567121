import React, { useEffect, useState } from "react";
import { supabase } from "../App";
import { useNavigate, useLocation } from 'react-router-dom';
import svgData from './svgData';

function Home() {



    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [partners, setPartners] = useState([]);
    const [user, setUser] = useState(null);

 const handleSignOut = async () => {

        // mixpanel.track('tap sign out');
        await supabase.auth.signOut();
        navigate('/');
        setUser(null);
    };
    useEffect(() => {
        fetchUser()
        fetchPartners()
    }, []);

    const fetchUser = async () => {
        const { data: { user } } = await supabase.auth.getUser()
        setUser(user)
        setLoading(false)
    }

    const fetchPartners = async () => {
        const { data, error } = await supabase.from('partners').select('*')
        if (error) {
            console.log('Error fetching data:', error.message)
        } else {
            setPartners(data)
        }
    }

    const openPartnerPage = (id) => {
        navigate(`/${id}`);
    }

    return <div className="styled-container">

<div style={{width: '100%'}}> <div className='icon'>
          
          <div dangerouslySetInnerHTML={{ __html: svgData.nomster }} /> 
          
         </div> </div>
       
    <div style={{width: '100%'}}>
    <h1 style={{fontWeight: 'bold', width: '100%'}}>Welcome {loading ? '...' : user.user_metadata.full_name == undefined ? '' : user.user_metadata.full_name.split(' ')[0]}!</h1>
    <div style={{height: '10px'}}/>
    <p className="label" style={{width: '100%'}} >Discover offers and loyalty programs from your favourite places.</p>
    
    </div>
            <ul>{ loading ? 'loading' : 
            
            partners.map(
                p => <li key={p.id}> <div className='partner-card' style={{display:'flex', width: '100%', alignItems: 'center', padding: '20px', gap: '10px'}}onClick={() => openPartnerPage(p.id)}><img src={p.photo} style={{height:'60px', aspectRatio: 1, objectFit: 'cover'}}/><h4>{p.name}</h4> </div> </li>
            ) 
            
            }</ul>
 <div style={{height: '120px'}}/>
<button className='secondary-button' onClick={handleSignOut}>Sign out</button>
<div style={{height: '40px'}}/>
<div className="styled-container" style={{alignItems:'flex-start', gap: '10px', textDecoration: 'underline'}}>

    <a href="/tos">Terms of Service</a>
    <a href="https://twitter.com/nomsterindia" target="_blank" rel="noopener noreferrer">Twitter</a>
    <a href="/privacy">Privacy Policy</a>


</div>


    </div>
}

export default Home