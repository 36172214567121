import React, { useState, useEffect } from 'react';
import { supabase } from '../App';
import { useLocation, useNavigate } from 'react-router-dom';
import QRCodeCanvas from 'qrcode.react';
import mixpanel from 'mixpanel-browser';
import svgData from './svgData';
import './styles.scss';
import { useStatus } from '../models/GlobalState';


function Partner() {

    
    const location = useLocation()
    const path = location.pathname
    const partner_id = path.split('/').slice(-1)[0]

    const [partner, setPartner] = useState(null)
    const [points, setPoints] = useState(0)
    const [p_loading, setPloading] = useState(true)
    

    const fetchPartner = async (user_id, partner_id) => {
        fetchPoints(user_id, partner_id)
        const { data, error } = await supabase.from('partners')
            .select('*')
            .eq('id', partner_id)
        if (error) {
            console.error(error)
        }
        else {
            setPartner(data)
            setPloading(false)
        }
    }

    const fetchPoints = async (user_id, partner_id) => {
        console.log('usrid', user_id)
        const { data, error } = await supabase.from('points')
            .select('*')
            .eq('user_id', user_id)
            .eq('partner_id', partner_id)
            
            if (error) {
                console.error(error)
            }
            else {
                // console.log('data', data)
                setPoints(data)
            }
    }


    const handlePartnerUpdated = () => {
// implement stream
    }


   
   


    const [number, setNumber] = useState(0);
    const [code, setCode] = useState('&%');
    const [customer, setCustomer] = useState(null);
    const [offer, setOffer] = useState(null);
    const [isScannerVisible, setScannerVisible] = useState(false);

    //bill box related state


    const [discount, setDiscount] = useState(0);


    const navigate = useNavigate();
    const { offer: offerSVG, nomster: nomsterSVG, tcwLogo } = svgData;
    const [user, setUser] = useState(null);
    const [transactions, setTransactions] = useState([]);
    const [offerCards, setOfferCards] = useState([]);

    const { status } = useStatus();

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (status === 'completed') {
            fetchOfferCards(user.user_id);
        }
    }, [user, status]);


    const checkIfNewCustomer = async (user_id) => {

        const { data } = await supabase
        .from('points')
        .select('*')
        .eq('user_id', user_id)
        .eq('partner_id', partner_id)


    
        if (data.length == 0) {

            setDisplayOfferCard(true);
                                        
        }
    }

    const handleCarouselScroll = () => {
        if (user) {
            mixpanel.track('scroll offer', { user_id: user.user_id });
            document.querySelector('.horizontal-scroll-container').removeEventListener('scroll', handleCarouselScroll);

        }
    };

    // useEffect(() => {
    //     const scrollContainer = document.querySelector('.horizontal-scroll-container');
    //     // scrollContainer.addEventListener('scroll', handleCarouselScroll);

    //     return () => {
    //         scrollContainer.removeEventListener('scroll', handleCarouselScroll);
    //     };
    // }, []);
    const fetchUser = async () => {
        console.log('Fetching user...'); // Debug log before fetching user
        const { data: { user } } = await supabase.auth.getUser();
        if (user) {
            const { data: userData, error } = await supabase
                .from('users')
                .select('*')
                .eq('user_id', user.id).single();
            console.log('User fetched:', userData); // Debug log for fetched user
            setUser(userData);
        } else {
            console.log('No user found, redirecting...'); // Debug log if no user is found
            navigate('/');
        }
    };

    useEffect(() => {

        fetchUser();
        
    }, []);

    useEffect(() => {
        if (user) {
            fetchPartner(user.user_id , partner_id);
            
            // fetchOfferCards(user.user_id);
            fetchTransactions(user.user_id);
            checkIfNewCustomer(user.user_id);

        }
    }, [user]);


    useEffect(() => {
        if (user) {
            console.log('Creating subscription for user:', user.user_id); // Debug log for subscription creation

            const filterString = `user_id=eq.${user.user_id}`;

            const subscription = supabase
                .channel('any')
                .on('postgres_changes', { event: '*', schema: 'public', table: 'transactions', filter: filterString }, handleTransactionUpdated)
                .on('postgres_changes', { event: '*', schema: 'public', table: 'offers', filter: filterString }, handleOfferUpdated)
                .on('postgres_changes', { event: '*', schema: 'public', table: 'users', filter: filterString }, handleUserUpdated)
                .subscribe()

            const partner_subscription = supabase
                .channel('any')
                .on('postgres_changes', { event: '*', schema: 'public', table: 'partners', filter: `id=eq.${partner_id}`}, handlePartnerUpdated)

            console.log('Subscription created for user:', user.user_id);

            return () => {
                subscription.unsubscribe();
            };
        }
    }, [user]);


    const handleUserUpdated = (event) => {
        if (event.new) {
            setUser(event.new);
        }
    }


   



    const fetchTransactions = async (userId) => {
        const { data, error } = await supabase.from('transactions').select('*').eq('user_id', userId).eq('partner_id', partner_id).order('created_at', { ascending: false });
        if (data) setTransactions(data);
        if (error) console.error('Error fetching transactions:', error);
    };

    const handleTransactionUpdated = (event) => {
        console.log('new');
        if (user && event.new && ['INSERT', 'UPDATE'].includes(event.eventType)) fetchTransactions(user.user_id);
    };

    const handleOfferUpdated = async (event) => {
        console.log('Handling offer updated:', event); // Debug log to see what the event object contains

        if (event.eventType === 'INSERT') {
            fetchOfferCards(user.user_id);
            const { new: newOffer } = event;
            console.log('New offer:', newOffer); // Debug log for the new offer

            if (newOffer.referral_uid && user) {

                const { data: inviterDetails } = await supabase
                    .from('users')
                    .select('*')
                    .eq('user_id', user.invited_by)
                    .single();

                console.log(inviterDetails.full_name)
                newOffer.inviter = inviterDetails;


                console.log('New offer with inviter:', newOffer); // Debug log for the new offer with inviter details
            }

            setOfferCards(currentOfferCards => {
                console.log('Current offer cards:', currentOfferCards); // Debug log for the current offer cards before updating
                const updatedOfferCards = [...currentOfferCards, newOffer];
                console.log('Updated offer cards:', updatedOfferCards); // Debug log for the updated offer cards after adding the new offer
                return updatedOfferCards;
            });
        } else if (event.eventType === 'UPDATE') {
            fetchOfferCards(user.user_id);
        }
    };

    const fetchOfferCards = async (userId) => {
        if (user) {
            const { data, error } = await supabase
                .from('offers')
                .select('*')
                .eq('user_id', userId)
                .order('created_at', { ascending: false });


            if (data) {
                console.log(data);
                const offersWithInviterDetails = await Promise.all(
                    data.map(async offer => {
                        if (offer && user) {
                            if (offer.type == 'invite') {

                                console.log('here');
                                const { data: inviter, error } = await supabase
                                    .from('users')
                                    .select('*')
                                    .eq('user_id', user.invited_by)
                                    .single()

                                offer.inviter = inviter;
                            } else if (offer.type = 'refer') {
                                const { data: ref, error } = await supabase
                                    .from('users')
                                    .select('*')
                                    .eq('user_id', offer.referral_uid)
                                    .single()
                                offer.ref = ref;
                            }
                            return offer;
                        }

                    })
                );
                setOfferCards(offersWithInviterDetails);
            } else {
                setOfferCards([]);
            } if (error) {
                console.error('Error fetching offers:', error);
            }
        }



    };


    const updateOffers = (event) => {
        const { new: updatedOffer, eventType } = event;
        setOfferCards(currentOfferCards => {
            if (eventType === 'INSERT') return [...currentOfferCards, updatedOffer];
            if (eventType === 'UPDATE') return currentOfferCards.map(card => (card.id === updatedOffer.id ? { ...updatedOffer } : card));
            return [...currentOfferCards];
        });
    };


    const formatDate = (dateString) => {
        const options = {
            day: 'numeric',
            month: 'short',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true
        };
        return new Date(dateString).toLocaleString(undefined, options);
    };



    const handleShareOffer = () => {
        const referralUrl = `https://nomster.in/?i=${user.user_id}&o=invite`;
        const shareMessage = `Can't espresso how awesome you are, here's a *FREE* coffee for you 👉 ${referralUrl}`;

        mixpanel.track('tap share offer', { user_id: user.user_id });
        if (navigator.share) {
            navigator.share({ title: 'Invite Friends', text: shareMessage, url: referralUrl })
                .then(() => {
                    alert('Shared successfully!');
                    mixpanel.track('share offer successful', { user_id: user.user_id });
                })
                .catch(error => console.error('Error sharing:', error));
        }
    };

    const [selectedCard, setSelectedCard] = useState(null);



    const handleApply = (indexOrType) => {
        if (selectedCard === indexOrType) {
            setSelectedCard(null);
            setDiscount(0);
            setOffer(null);
            mixpanel.track('deselect offer');

        } else {
            setSelectedCard(indexOrType);
            if (indexOrType == 'balance') {
                setDiscount(points[0].value);
                setOffer(null);
                // mixpanel.track('select cash discount', { type: 'balance', balance: user.balance });
            }
            else {
                setOffer(offerCards[indexOrType]);
                setDiscount(0);


                mixpanel.track('select offer', { type: offerCards[indexOrType].type });

            }
            // calculateFinalBill(indexOrType);
        }


        // setDiscount(user.balance)
    };

    const renderCard = (card, index) => {
        if (card.is_used) return null;

        const imgUrl = card.type === 'invite' ? card.inviter?.avatar_url : card.ref?.avatar_url;
        const name = card.type === 'invite' ? card.inviter?.full_name : card.ref?.full_name;
        const firstName = name.split(' ')[0];
        const isCardSelected = selectedCard === index;

        return (

            (card.type === 'invite' || card.type === 'refer') &&

            <div key={index} className="offer-card">


                {/* <img style={{ borderRadius: '100px', width: '24px' }} src={imgUrl} alt="" /> */}
                <div>
                    <p className='label' >{card.type === 'invite' ? `${firstName} invited you` : card.type === 'refer' ? `You invited ${firstName}` : ''}</p>
                    <h1 style={{ textAlign: 'left' }}>{card.name}</h1>

                </div>
                {number < card.value ? <button disabled className='secondary-button' style={{ fontSize: '12px', whiteSpace: 'normal', maxWidth: '140px', fontWeight: 'normal' }}>on orders above ₹149</button>
                    : card.is_unlocked ? <button
                        className={`secondary-button ${isCardSelected ? 'applied-state' : ''}`}
                        onClick={() => handleApply(index)}
                    >
                        {isCardSelected ? "Selected" : "Select"}
                    </button> : <button disabled className='secondary-button' style={{ fontSize: '12px', whiteSpace: 'normal', maxWidth: '140px', fontWeight: 'normal' }}>Unlocks after their first order</button>}

            </div>


        );
    };

    const BalanceCard = () => {
        const isBalanceCardSelected = selectedCard === 'balance';

        return (
            <div >
                <div >
                   <h1 style={{ textAlign: 'right', fontWeight: 'bold' }}>{ points[0] != undefined ? `${points[0].value.toFixed(2)}` : `${(0).toFixed(2)}`}</h1> <p className='label' style={{ textAlign: 'right' }}>coins</p>
                    

                </div>
                
            </div>
        );
    };











    const renderTransactions = (transactions) => {

        return transactions.map(({ id, type, created_at, amount, is_confirmed, bill_value }, i) => (
            is_confirmed ?
                <li key={id} style={{ listStyleType: 'none' }}>
                    <div className='list-item'>
                        <div className='wallet-balance' style={{ padding: 0 }}>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                <div style={{ backgroundColor: '#fff', borderRadius: '100px' }}>
                                    <p style={{ fontSize: '32px', textAlign: 'center', height: '40px', width: '40px', borderRadius: '100px', padding: '2px' }}>
                                        {type === 'spend' ? '₹' : (type === 'refer' || type === 'invite') ? '🥤' : '₹'}</p>
                                </div>
                                <div>
                                    <p style={{ fontWeight: 'bold', marginBottom: '4px' }}>
                                        {
                                            (() => {
                                                let message = '';

                                                if (type === 'spend') {
                                                    message += `₹${Math.abs(amount.toFixed(2))} discount`;
                                                } else if (type === 'invite' || type === 'refer') {
                                                    message += `Free drink (worth ₹${amount})`;
                                                }

                                                const cashback = ((bill_value - (type === 'spend' ? amount : 0)) * 0.1).toFixed(2);
                                                if (cashback > 0) {
                                                    message += (message ? ' + ' : '') + `${cashback} cashback`;
                                                }

                                                return message;
                                            })()
                                        }
                                    </p>
                                    <p style={{ color: 'gray' }}>{formatDate(created_at)}</p>
                                </div>
                            </div>
                            {!is_confirmed && <p style={{ color: 'brown', fontStyle: 'italic' }}>Processing</p>}
                        </div>
                    </div>
                    <div style={{height:'1px', width: '100%', backgroundColor: '#eee'}}/>
                </li> : null
        ));
    };






    const handleClick = (url, channel) => {
        window.open(url, '_blank');

        mixpanel.track('tap link', { url: url, channel: channel });
    }

    const handleConfirm = async () => {



        if (!user) {
            console.error('User is null');
            return;
        }
        if (number < 199) {
            // console.error('Bill value is zero or negative. Operation not allowed.');

            setInputError("Minimum order value ₹199");
            return;
        }

        
        setIsLoading(true);

        const { data } = await supabase
        .from('points')
        .select('*')
        .eq('user_id', user.user_id)
        .eq('partner_id', partner_id)


    
        if (data?.length == 0) {

            const {data, error} = await supabase
                                    .from('points')
                                    .insert({
                                        user_id: user.user_id, 
                                        partner_id: partner_id, 
                                        value: 0.0})
                                        
        }
           




        const transaction = {
            user_id: user.user_id,
            partner_id: partner_id,
            bill_value: number,
            is_confirmed: false,
            type: 'none',

        }


        if (selectedCard == 'balance') {
            // if (true){
            transaction.amount = Math.min(number, points[0].value);
            transaction.type = 'spend';
        }


        else if (offerCards[selectedCard]?.type == 'refer') {

            transaction.amount = offer.value;
            transaction.offer_id = offer.id;
            transaction.type = 'refer';

            const updatedCards = [...offerCards];

            // Update the is_used property of the selectedCard
            updatedCards[selectedCard].is_used = true;

            // Update the state or wherever you're storing the offerCards
            setOfferCards(updatedCards);

        }


        else if (offerCards[selectedCard]?.type == 'invite') {

            transaction.amount = offer.value;
            transaction.offer_id = offer.id;
            transaction.type = 'invite';
            transaction.ref_offer_id = offer.ref_offer_id;


        }

        mixpanel.track('claim rewards', { transaction: transaction });

        await supabase.from('transactions').insert(transaction);

        



        clearState();
        fetchTransactions(user.user_id);

        return (setIsLoading(false));
    };



    function clearState() {
        setNumber(0)
        setCode('&%')
        setCustomer(null)
        setOffer(null)
        setSelectedCard(null)
        setDiscount(0)
    }

    function handleCancel() {
        clearState()
    }

    const handleInputChange = e => {

        if (e.target.value >= 99) {
            setInputError('')
        }

        setNumber(e.target.value);
    };

    const [inputError, setInputError] = useState('');
    const [isFocused, setIsFocused] = useState(false);
    const [displayOfferCard, setDisplayOfferCard] = useState(false);

    

    const handleClaimClick = async () => {

        const {data, error} = await supabase
        .from('points')
        .insert({
            user_id: user.user_id, 
            partner_id: partner_id, 
            value: 15.0})

        window.location.reload()

        
      
  };

  

  const handleRequestCancel = async() => {
    await supabase.from('transactions').delete().eq('id', transactions[0].id);
        clearState()
        window.location.reload()
  }

    return (
        <div className="styled-container">
           

           <div style={{width:'100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}> 
           <button className='secondary-button' onClick={()=> navigate('/home')}>Back</button> 
           <a style={{textDecoration: 'underline'}} styleonClick={() => handleClick('https://goo.gl/maps/ssxsgRkP3Q8EES979', 'map')}>Find directions</a> </div>
          
           {displayOfferCard ? <div className='navbar' style={{backgroundColor: '#369027', borderRadius: '16px'}}>
        <div style={{display: 'flex', alignItems:'center', gap: '20px'}}> 
        
           <p style={{color: '#fff'}}>Welcome, here is a free gift for you!</p></div>

     
              

                <button className='secondary-button' onClick={handleClaimClick}>Claim 15 coins</button>

                {/* <div dangerouslySetInnerHTML={{ __html: svgData.nomster }} /> */}
            </div> : <div />}
            <div style={{ width: '100%' }}>
               
                <div className='hero-card' style={{aspectRatio: 1.6, justifyContent:'space-between'}}>
                    <div className='wallet-balance' style={{ alignItems: 'center', justifyContent: 'space-between', gap: 0, padding: 0 }}>

                        
                        {p_loading ?  '' : <img style={{height:'44px', aspectRatio: 1, objectFit: 'cover'}} src={partner[0].photo}/>}
                        
                        {<BalanceCard />}
                    </div>
                    <p className='label'>{p_loading ? 'loading' : partner[0].name}
                        </p>
                   
                        <div />

                </div>
             </div>

            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', gap: '12px' }}>



                {transactions.some(transaction => !transaction.is_confirmed) ?

                    <div className='claim-box' style={{ background: '#fff', padding: '48px' }}>

                        <h3 style={{ width: '100%', textAlign: 'center', lineHeight: '1.2' }} >{'Waiting for confirmation...'}
                        </h3>

                        <p style={{textAlign: 'center'}} >{`Please pay ₹${transactions[0].bill_value - transactions[0].amount} with your preferred payment method.`}
                        </p>
                        <button className='secondary-button' onClick={handleRequestCancel} style={{width: '100%'}}>Cancel</button>










                    </div>

                    :



                    <div className='claim-box' >
                        <div className="input-wrapper">
                            <label htmlFor="billAmount" className={isFocused ? "active" : ""}>Enter bill amount</label>
                            <input
                                id="billAmount"
                                className='bill-input'
                                placeholder={isFocused ? '₹ XX.XX' : ''}
                                type="number"
                                value={number ? number : ''}
                                onChange={handleInputChange}
                                onFocus={() => (setIsFocused(true),
                                    mixpanel.track('tap bill input', { bill: number }))}
                                onBlur={() => {
                                    if (!number) setIsFocused(false);

                                    mixpanel.track('tap out bill input', { bill: number });
                                }}
                            />
                           
                        </div>
                        {inputError && (
                <p style={{ color: "red", fontWeight: '500' }}>{inputError}</p>
              )}
                        




                       


                        {points[0] == undefined || points[0].value == 0 ? '' : <div className='wallet-balance' >
                         <div style={{display:'flex', alignItems: 'center', gap: '8px'}}>   <input type='checkbox' onChange={() => handleApply('balance')}/><p>{`Pay using coins`}</p> </div>{!discount ? '' : <p>-₹{Math.min(number, points[0].value).toFixed(2)}</p> }</div> }
                       {number ? <div className='wallet-balance'>
                            <p style={{fontWeight: '500'}}>To pay</p>
                            <p  style={{fontWeight: '500'}}>₹{Math.max(number - (selectedCard == 'balance' ? points[0].value : 0), 0).toFixed(2)}</p>
                        </div> : ''}
                        <div className='stack-h-fill' style={{ justifyContent: 'space-between', width: '100%', gap: '10px' }}>
                            {/* <button className='secondary-button' onClick={handleCancel}>Cancel</button> */}

                            {isLoading ? '' : <button className='scanner' disabled={number < 99} onClick={handleConfirm}>
                                {parseFloat((0.1 * (Math.max(number - (selectedCard == 'balance' ? points[0].value : 0), 0))).toFixed(2))
                                    ? <>
                                        Claim {(0.1 * (Math.max(number - (selectedCard == 'balance' ? points[0].value : 0), 0))).toFixed(2)} cashback
                                    </>
                                    : 'Claim discount'}
                            </button>}
                        </div>


                    </div>






                }   </div>

<div style={{height: '40px'}}/>
            <div style={{width: '100%'}}><h4>History</h4></div>
            <div className='hero-card' >
            {transactions.length == 0 ?  <p className='label' style={{width: '100%', textAlign: 'center'}}>No transactions yet.</p>: <ul className='list'>{renderTransactions(transactions)}</ul>}
            
            </div>
           
            
        </div>
    );




}

export default Partner;

