import React, { useEffect, useState } from "react";
import { supabase } from "../App.js";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';


function PartnerLogin() {
  const [activeTab, setActiveTab] = useState(false);

  const navigate = useNavigate();
  const [partnerName, setPartnerName] = useState("");
  const [partnerEmail, setPartnerEmail] = useState("");
  const [mapsLocation, setMapsLocation] = useState("");
  const [image, setImage] = useState(null);

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState(null);
  const [loading, setLoading] = useState(false);

  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [uploadedUrl, setUploadedUrl] = useState('');

  


  const handlePasswordChange = (event) => {
    const newPassword = event.target.value;
    if (newPassword.length < 8) {
      setPasswordError("Password must be 8 characters or more");
    } else {
      setPasswordError("");
    }

    setPassword(newPassword);
  };

  const handleLogin = async (e) => {
    setLoading(true);
    const { data, error } = await supabase.auth.signInWithPassword({
      email: partnerEmail,
      password: password,
    });
    if (error) {
      console.error("Sign in failed");
    } else {
      navigate(`/admin/${data.user.id}`);
    }

    setLoading(false);
  };

  const handleSubmit = async (event) => {
    setLoading(true);


    if (password.length < 8) {
      setPasswordError("Passwords is too short");
    }


    const { data, error } = await supabase.auth.signUp({
      email: partnerEmail,
      password: password,
    });

    if (error) {
      alert("Sign up failed");
      console.error(error)
    } else {
      alert("Success");


      setUploading(true)
    // const file = event.target.files[0];
    const fileName = '/partner_logos/' + data.user.id + '.png'
    // setFile(file);

    const { d, e } = await supabase.storage
      .from('images')
      .upload(fileName, file);

    if (e) {
      console.error(e);
      setUploading(false);
    } else {
      let upload_url = 'https://xxsawwpbahvabbaljjuu.supabase.co/storage/v1/object/public/images' + fileName
      setUploadedUrl(upload_url);
      setUploading(false);
      const { error } = await supabase
        .from("partners")
        .insert({ id: data.user.id, name: partnerName, email: partnerEmail, location: mapsLocation, photo: upload_url })
        .select();
    }

      
    }

    setLoading(false);
    console.log("sign up data:", data);
  };

  const handleTabChange = () => {
    setActiveTab(!activeTab);
  };

  return (
    <div className="styled-container partner-container" style={{ gap: "10px" }}>
      {loading ? (
        "loading"
      ) : (
        <>
          

          {activeTab && (
            <>
              <h1 style={{fontWeight: 'bold'}}>Become a partner 🍕</h1>
              <div style={{height: '40px'}}/>
              <input
                className="partner-input"
                onChange={(e) => setPartnerName(e.target.value)}
                placeholder="Partner name"
              />
              <input
                className="partner-input"
                onChange={(e) => setPartnerEmail(e.target.value)}
                placeholder="Partner email"
              />
              <input
                className="partner-input"
                placeholder="Choose password"
                value={password}
                onChange={handlePasswordChange}
              />
              {passwordError && (
                <p style={{ color: "red" }}>{passwordError}</p>
              )}
              <input
                className="partner-input"
                onChange={(e) => setMapsLocation(e.target.value)}
                placeholder="Maps location"
              />
              <input
                type="file"
                onChange={(e) => setFile(e.target.files[0])}
              />

<div style={{height: '40px'}}/>
              <button className="scanner" onClick={handleSubmit}>Continue</button>
            </>
          )}

          {!activeTab && (
            <>
              <h1 style={{fontWeight: 'bold'}}>Partner Sign In</h1>
              <div style={{height: '40px'}}/>
              <input
                className="partner-input"
                onChange={(e) => setPartnerEmail(e.target.value)}
                placeholder="Partner email"
              />
              <input
                className="partner-input"
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Partner password"
              />
              <div style={{height: '40px'}}/>
              <button className="scanner" onClick={handleLogin}>Continue</button>
            
            </>
            
          )}
          <div style={{height: '40px'}}/>
            <div className="tab-buttons">
            <a style={{textDecoration: 'underline'}}
              onClick={() => handleTabChange()}
            >
              {!activeTab ? 'Not a member? Sign up' : 'Already a member? Sign in'}
            </a>
           
          </div>
        </>
        
      )}
    </div>
  );
}

export default PartnerLogin;