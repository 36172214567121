import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Routes, useNavigate, Navigate } from 'react-router-dom';
import Login from './views/Login.js';
import Home from './views/Home.js';
import Partner from './views/Partner.js';
import { createClient } from '@supabase/supabase-js';
import { indigo } from '@mui/material/colors';
import Admin from './views/Admin.js';
import mixpanel from 'mixpanel-browser';
import Privacy from './views/Privacy.js';
import Tos from './views/Tos.js';
import { StatusProvider } from './models/GlobalState.js';
import './App.scss';
import PartnerLogin from './views/PartnerLogin.js';
import svgData from './views/svgData';

const MIXPANEL_TOKEN = '9937813cca9141bdb4adf8b6a5345d44'; // Replace with your Mixpanel project token


mixpanel.init(MIXPANEL_TOKEN, { debug: true, track_pageview: true, persistence: 'localStorage' });



const supabaseUrl = process.env.REACT_APP_SUPABASE_URL
const supabaseKey = process.env.REACT_APP_ANON_KEY
export const supabase = createClient(supabaseUrl, supabaseKey);



function App() {

  useEffect(() => {

    window.addEventListener('beforeinstallprompt', (e) => {
        // Prevent Chrome 67 and earlier from automatically showing the prompt
        e.preventDefault();
        // Stash the event so it can be triggered later.
        setDeferredPrompt(e);
        // Trigger the prompt

    });
}, []);

  const [deferredPrompt, setDeferredPrompt] = useState(null);

  const handleInstallClick = () => {
    mixpanel.track('tap install app');
    if (deferredPrompt) {
        deferredPrompt.prompt();
        deferredPrompt.userChoice.then((choiceResult) => {
            if (choiceResult.outcome === 'accepted') {
                mixpanel.track('accept install prompt');
                console.log('User accepted the A2HS prompt');
            } else {

                mixpanel.track('dismiss install prompt');
                console.log('User dismissed the A2HS prompt');
            }
            setDeferredPrompt(null);
        });
    }
};


  return (
    <StatusProvider>
       {deferredPrompt ? <div className='navbar'>
        <div style={{display: 'flex', alignItems:'center', gap: '20px'}}> 
        <div className='icon'>
          
         <div dangerouslySetInnerHTML={{ __html: svgData.nomster }} /> 
         
        </div>
           <p >Install nomster for easy access</p></div>

     
              

                <button className='scanner' onClick={handleInstallClick}>Install app</button>

                {/* <div dangerouslySetInnerHTML={{ __html: svgData.nomster }} /> */}
            </div> : <div />}
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <Routes>
          <Route exact path='/' element={<Login />} />
          <Route exact path='' element={<Navigate to="/" />} />
          <Route path='/home' element={<Home />} />
          <Route exact path='/:id' element={<Partner />} />
          <Route exact path='/partner' element={<PartnerLogin/>} />
          <Route path='/admin/:id' element={<Admin />} />
          <Route path='/privacy' element={<Privacy />} />
          <Route path='/tos' element={<Tos />} />
        </Routes>
      </BrowserRouter>
    </StatusProvider>

  );
  
}

export default App;
